import type { FormEventHandler } from 'react';
import React, { useMemo, useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { isEmail } from '@autofidev/validation';
import type { Dealer } from '@autofidev/models/generated';

import api from '@client/lib/api';
import type { RequestedOfferType } from '@client/graphql/generated';
import Button from '@components/AFComponentsButton';
import { SelectInput } from '@components/SelectInput';
import TextInput from '@components/TextInput';
import Panel, { Content, Footer, Header } from '@client/components/Panel';
import { isEmbedded } from '@client/lib/helpers/isEmbedded';
import { footerText } from '@client/helpers';
import Checkbox from '@components/Checkbox';
import BackendPath from '@components/HeaderBackendPath';
import StyledInput from '@components/StyledInput';
import Dropdown from '@client/components/Dropdown';
import { formatMessage, getExpressCheckoutTrackingData } from '@public/js/loan-application/utils';
import validate from '@public/js/validate';
import {
	trackGoogleAnalytics4Event,
	trackGoogleUniversalAnalyticsEvent,
	trackShiftDigitalEvent,
} from '@client/lib/helpers/analytics';
import { useGoogleTagScript } from '@hooks/useGoogleTagScript';
import { ENUM_SUFFIX_VALUES, ENUM_TITLE_VALUES, LOCALE_STRINGS, PANEL_TRACKING_NAME } from './constants';
import styles from './index.scss';
import type { Action } from './utils';
import { ActionType } from './utils';
import PhoneInput from '@components/AFComponentsPhoneInput';
import { useLaunchDarklyFlags } from '@client/hooks/useLaunchDarklyFlags';
import PartnerBackButton from '@client/components/PartnerBackButton';
import track from '@public/v3/track';

type FormChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface RenderFields {
	renderFieldEmail?: boolean;
	renderFieldNameFirst?: boolean;
	renderFieldNameLast?: boolean;
	renderFieldNameMiddle?: boolean;
	renderFieldPhone?: boolean;
	renderFieldSuffix?: boolean;
	renderFieldTaxExempt?: boolean;
	renderFieldTitle?: boolean;
}

export type Props = {
	currentApplicant?: string;
	dealer: Dealer;
	dealerAssociateId?: string;
	email?: string;
	fields: RenderFields;
	firstName?: string;
	handleBack?: Function;
	handleClose?: () => void;
	handleSubmit: FormEventHandler<HTMLFormElement>;
	handleTaxExemptToggle: Function;
	hideStandAloneClose?: boolean;
	isInStore: boolean;
	isStandAlone?: boolean;
	isSubmitting?: boolean;
	isTaxExempt?: boolean;
	lastName?: string;
	middleName?: string;
	phone?: string;
	requestedOfferType?: RequestedOfferType;
	suffix?: string;
	title?: string;
	// TODO: fix this
	vehicle?: any;
};

const IdentityForm = ({
	currentApplicant = 'applicant',
	dealer,
	dealerAssociateId,
	email,
	fields,
	firstName,
	handleBack,
	handleClose,
	handleSubmit,
	handleTaxExemptToggle,
	hideStandAloneClose,
	isInStore,
	isStandAlone = false,
	isSubmitting = false,
	isTaxExempt = false,
	lastName,
	middleName,
	outerDispatch,
	phone,
	requestedOfferType,
	suffix,
	title,
	vehicle,
}: Props & { outerDispatch: React.Dispatch<Action> }) => {
	const launchDarklyFlags = useLaunchDarklyFlags();

	const { enableDealerAssociateList, enableNameSuffix, checkDuplicateEmail } = launchDarklyFlags;
	const isValid = useMemo(() => {
		if (!fields) {
			return false;
		}

		const isEmailInvalid = fields.renderFieldEmail && (!email || !isEmail(email));
		const isTitleInvalid = fields.renderFieldTitle && !validate.text(title, 2);
		const isFirstNameInvalid = fields.renderFieldNameFirst && !validate.name(firstName);
		const isMiddleNameInvalid = fields.renderFieldNameMiddle && !validate.middlename(middleName);
		const isLastNameInvalid = fields.renderFieldNameLast && !validate.name(lastName);
		const isPhoneInvalid = fields.renderFieldPhone && !validate.phone(phone);

		const hasError =
			isEmailInvalid ||
			isTitleInvalid ||
			isFirstNameInvalid ||
			isLastNameInvalid ||
			isMiddleNameInvalid ||
			isPhoneInvalid;

		return !hasError;
	}, [email, fields, firstName, lastName, middleName, phone, title]);

	const showBack = !isStandAlone || currentApplicant === 'cosigner';
	const embedded = useMemo(() => !isEmbedded(), []);
	const showClose = !hideStandAloneClose && embedded && Boolean(isStandAlone);

	const [dealerAssociates, setDealerAssociates] = useState([]);
	const [associatesList, setAssociatesList] = useState([]);
	const [dealerAssociateOption, setDealerAssociateOption] = useState(null);

	const [duplicateEmailState, setDuplicateEmailState] = useState({
		isLoading: false,
		isDuplicate: false,
	});

	const checkDuplicateConsumerEmail = useMemo(
		() =>
			debounce(async (emailValue: string) => {
				if (!emailValue || !isEmail(emailValue)) {
					setDuplicateEmailState({ isLoading: false, isDuplicate: false });
					return;
				}
				setDuplicateEmailState({ isLoading: true, isDuplicate: false });
				try {
					const stateName = `${firstName} ${lastName}`.trim().replace(/( + )/g, ' ');
					const url = new URL(`${window.baseUrl}/checkDuplicateConsumerEmail`);
					url.searchParams.append('email', emailValue);
					url.searchParams.append('dealerCode', dealer.code);
					url.searchParams.append('name', stateName);
					const response = await api.get(url.toString(), {});
					setDuplicateEmailState({ isLoading: false, isDuplicate: response?.isDuplicate });
				} catch (error) {
					setDuplicateEmailState({ isLoading: false, isDuplicate: false });
				}
			}, 500),
		[dealer.code, firstName, lastName]
	);

	useEffect(() => {
		if (enableDealerAssociateList && isStandAlone && dealer && currentApplicant === 'applicant') {
			const fetchDealerAssociates = async () => {
				const associatesResponse = await api.get(`${window.baseUrl}/dealer/${dealer?.code}/associates`, {});
				setDealerAssociates(associatesResponse?.dealerAssociates || []);
			};

			fetchDealerAssociates();
		} else {
			setDealerAssociates([]);
		}
	}, [dealer?.code, currentApplicant, isStandAlone, enableDealerAssociateList]);

	useEffect(() => {
		const list = dealerAssociates?.map((user) => {
			const { first, last } = user.name;
			const label = last ? `${first} ${last}` : first;
			return {
				label,
				value: user._id,
			};
		});
		setAssociatesList(list);
		const currentAssociate = list?.find((associate) => associate.value === dealerAssociateId);
		if (currentAssociate) {
			setDealerAssociateOption(currentAssociate);
		}
	}, [dealerAssociates]);

	useGoogleTagScript({
		dealer,
		shouldEmbed: isStandAlone,
		afterEffect: () => {
			if (isStandAlone) {
				trackGoogleAnalytics4Event(
					'asc_retail_process',
					{
						flow_name: 'credit_app',
						flow_outcome: 'recalc',
						item_payment: requestedOfferType,
						page_type: 'finance',
						vehicle,
					},
					{ dealer, isInStore }
				);
			}
		},
	});

	useEffect(() => {
		if (isStandAlone) {
			const originUrl = new URLSearchParams(window.location.search).get('postMessageTarget');

			// SCA == "standalone credit app". The following line only works when
			// window.ga is defined, which only happens for non-embedded SCA (SCA in a
			// modal iframe created by panda when a SCA CTA is clicked). window.ga is
			// defined when the loanapp is created, which is before this form loads for
			// non-embedded SCA, but after this form is submitted for embedded SCA (SCA
			// in an iframe placed by the dealer on their site, not involving panda).
			trackGoogleUniversalAnalyticsEvent(`SCA started from: ${originUrl}`, {
				dealer,
			});
		}
	}, [isStandAlone]);

	useEffect(() => {
		trackShiftDigitalEvent('drCreditAppShown', { dealer, isInStore });
	}, [dealer, isInStore]);

	// Add a useEffect to check the email on mount
	useEffect(() => {
		if (email && isEmail(email) && !isStandAlone && checkDuplicateEmail) {
			checkDuplicateConsumerEmail(email); // Call the API if the email is valid
		}
	}, [email, checkDuplicateConsumerEmail, isStandAlone, checkDuplicateEmail]);

	const [shiftCreditAppStartEventHasBeenSent, setShiftCreditAppStartEventHasBeenSent] = useState(false);
	const trackShiftCreditAppStartEvent = () => {
		if (!shiftCreditAppStartEventHasBeenSent) {
			trackShiftDigitalEvent('drCreditAppStart', { dealer, isInStore });
			setShiftCreditAppStartEventHasBeenSent(true);
		}
	};

	const handleSelect = (option: { label: string; value: string }) => {
		outerDispatch({ type: ActionType.SetDealerAssociateId, dealerAssociateId: option.value });
		setDealerAssociateOption(option);
	};

	const handleEmailChange = (event: FormChangeEvent) => {
		const emailValue = event.target.value;

		outerDispatch({ type: ActionType.SetEmail, email: emailValue });
		trackShiftCreditAppStartEvent();
	};

	const emailHint = useMemo(() => {
		if (!email) {
			return '';
		}

		if (isEmail(email)) {
			if (!isStandAlone && checkDuplicateEmail) {
				if (duplicateEmailState.isLoading) {
					return LOCALE_STRINGS.checkingEmail;
				}
				if (duplicateEmailState.isDuplicate) {
					track({ label: 'Dealmaker: Duplicate Email Used' }, getExpressCheckoutTrackingData());
					return LOCALE_STRINGS.duplicateEmail;
				}
			}
			return '';
		}

		return LOCALE_STRINGS.invalidEmail;
	}, [email, duplicateEmailState.isLoading, duplicateEmailState.isDuplicate, isStandAlone, checkDuplicateEmail]);

	return (
		<Panel id="identity">
			<span data-subpanel={PANEL_TRACKING_NAME} />

			<Header>
				<BackendPath />
				{/* Needs to be hidden for partners such as Ford model-e */}
				{showBack && <PartnerBackButton onBack={handleBack} />}
				{showClose && (
					<div className={`afi-v2-close afi-v2-fast ${styles.close}`} onClick={handleClose}>
						{`${formatMessage('common/close')} ✕`}
					</div>
				)}
				<Header.Title>{LOCALE_STRINGS.panelHeader(currentApplicant)}</Header.Title>
				<Header.Description>{LOCALE_STRINGS.panelDescription(currentApplicant)}</Header.Description>
			</Header>

			<hr />
			<Content>
				<form className="afi-v2-form" onSubmit={handleSubmit}>
					{fields?.renderFieldEmail && (
						<div className="afi-form-row">
							<StyledInput
								InputComponent={TextInput}
								hint={emailHint}
								id="email"
								inputClassNames={duplicateEmailState.isDuplicate ? 'hasError' : ''}
								label={LOCALE_STRINGS.email}
								name="email"
								onChange={handleEmailChange}
								placeholder={LOCALE_STRINGS.email}
								validator={(value: string) => value && isEmail(value)}
								value={email}
							/>
						</div>
					)}

					{fields?.renderFieldTitle && (
						<div className="afi-form-row" data-testid="title-field">
							{title && <div className={styles.titleLabel}>{LOCALE_STRINGS.title}</div>}
							<SelectInput
								name="title"
								onChange={(selection: { label: string; value: string }) => {
									outerDispatch({ type: ActionType.SetTitle, title: selection.value });
									trackShiftCreditAppStartEvent();
								}}
								options={ENUM_TITLE_VALUES}
								placeholder={LOCALE_STRINGS.title}
								value={title}
							/>
							<div className="afi-v2-space-20" />
						</div>
					)}

					{fields?.renderFieldNameFirst && (
						<div className="afi-form-row">
							<StyledInput
								InputComponent={TextInput}
								hint={
									validate.hasNoNumbers(firstName) ? LOCALE_STRINGS.nameMustMatch : LOCALE_STRINGS.numbersNotAllowed
								}
								id="nameFirst"
								label={LOCALE_STRINGS.nameFirst}
								name="nameFirst"
								onChange={(event: FormChangeEvent) => {
									outerDispatch({ type: ActionType.SetFirstName, firstName: event.target.value });
									trackShiftCreditAppStartEvent();
								}}
								placeholder={LOCALE_STRINGS.nameFirst}
								validator={(value: string) => validate.name(value)}
								value={firstName}
							/>
						</div>
					)}

					{fields?.renderFieldNameMiddle && (
						<div className="afi-form-row">
							<StyledInput
								InputComponent={TextInput}
								hint={
									validate.hasNoNumbers(middleName) ? LOCALE_STRINGS.nameMustMatch : LOCALE_STRINGS.numbersNotAllowed
								}
								id="nameMiddle"
								label={LOCALE_STRINGS.nameMiddle}
								name="nameMiddle"
								onChange={(event: FormChangeEvent) => {
									outerDispatch({ type: ActionType.SetMiddleName, middleName: event.target.value });
									trackShiftCreditAppStartEvent();
								}}
								placeholder={LOCALE_STRINGS.nameMiddle}
								required={false}
								validator={(value: string) => validate.middlename(value)}
								value={middleName}
							/>
						</div>
					)}

					{fields?.renderFieldNameLast && (
						<div className="afi-form-row">
							<StyledInput
								InputComponent={TextInput}
								hint={validate.hasNoNumbers(lastName) ? LOCALE_STRINGS.nameMustMatch : LOCALE_STRINGS.numbersNotAllowed}
								id="nameLast"
								label={LOCALE_STRINGS.nameLast}
								name="nameLast"
								onChange={(event: FormChangeEvent) => {
									outerDispatch({ type: ActionType.SetLastName, lastName: event.target.value });
									trackShiftCreditAppStartEvent();
								}}
								placeholder={LOCALE_STRINGS.nameLast}
								validator={(value: string) => validate.name(value)}
								value={lastName}
							/>
						</div>
					)}

					{fields?.renderFieldSuffix && enableNameSuffix && (
						<div className="afi-form-row" data-testid="title-field">
							{suffix && <div className={styles.titleLabel}>{LOCALE_STRINGS.suffix}</div>}
							<SelectInput
								isSearchable={false}
								name="suffix"
								onChange={(selection: { label: string; value: string }) => {
									outerDispatch({ type: ActionType.SetSuffix, suffix: selection.value });
									trackShiftCreditAppStartEvent();
								}}
								options={ENUM_SUFFIX_VALUES}
								placeholder={LOCALE_STRINGS.suffix}
								value={suffix}
							/>
							<div className="afi-v2-space-20" />
						</div>
					)}

					{fields?.renderFieldPhone && (
						<div className="afi-form-row">
							<StyledInput
								// @ts-expect-error InputComponent is not a valid prop
								InputComponent={PhoneInput}
								hint={!phone || validate.phone(phone) ? '' : LOCALE_STRINGS.invalidPhone}
								id="phone"
								label={LOCALE_STRINGS.phone}
								name="phone"
								onChange={(event: FormChangeEvent) => {
									outerDispatch({ type: ActionType.SetPhone, phone: event.target.value });
									trackShiftCreditAppStartEvent();
								}}
								placeholder={LOCALE_STRINGS.phone}
								validator={(value: string) => validate.phone(value)}
								value={phone}
							/>
						</div>
					)}

					{fields?.renderFieldTaxExempt && (
						<>
							<div className="afi-v2-space-20" />
							<div className="afi-form-row">
								<div className="col-xs-1">
									<Checkbox
										isSelected={isTaxExempt}
										onClick={() => {
											handleTaxExemptToggle();
											trackShiftCreditAppStartEvent();
										}}
									/>
								</div>
								<div className="col-xs-11">
									<div className={styles.checkboxContainer}>
										<div className={styles.checkboxLabel}>{LOCALE_STRINGS.isTaxExempt}</div>
										<div className={styles.checkboxHint}>{LOCALE_STRINGS.isTaxExemptHint}</div>
									</div>
								</div>
							</div>
						</>
					)}
					{dealerAssociates?.length > 0 && (
						<div className="afi-form-row">
							<Dropdown
								className="searchable-dropdown"
								label="Are you working with a dealership contact?"
								onSelect={handleSelect}
								options={associatesList}
								placeholder="None"
								value={dealerAssociateOption}
							/>
						</div>
					)}
					<div className="afi-v2-space-40" />
					<Button
						active={isValid}
						className="afi-v2-continue panel-btn"
						disabled={!isValid || isSubmitting}
						onClick={trackShiftCreditAppStartEvent}
						type="submit"
					>
						{formatMessage('common/continue')}
					</Button>
				</form>
			</Content>
			<Footer text={footerText(dealer.name)} title={formatMessage('common/steve-you-should-know')} />
		</Panel>
	);
};

export { IdentityForm };
