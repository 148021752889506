const invoke = require('lodash/invoke');
const pick = require('lodash/pick');

const calculateProductsAmount = (productsOffered = [], offerType = '') => {
	const upperOfferType = offerType.toUpperCase();
	const productPrice = upperOfferType === 'LEASE' ? 'suggestedRetailPricePretax' : 'suggestedRetailPrice';
	return productsOffered
		.filter((product) => product.userSelected && product.offerType === upperOfferType)
		.reduce((total, product) => total + (product[productPrice] || 0), 0);
};

const getOptionId = (product) => {
	const { category, offerType, termMiles = 0, termMonths = 0, nameI18n = { en: '' }, deductible = 0 } = product;
	const { visits = 0 } = getProductOption(product);

	return [offerType, category, nameI18n.en, termMiles, termMonths, visits, deductible].join(':');
};

const getProductIdOptionCompositeKey = ({ productId, category, termMiles = 0, termMonths = 0 }) => {
	// termMonths never match for gap waiver products, which are only available for current offer termMonths,
	// so we remove them from the composite key to get a match (see applicableProduct filter for similar behavior)
	if (category === 'gap waiver') {
		return [productId, termMiles].join(':');
	}
	return [productId, termMiles, termMonths].join(':');
};
const findMatchingProductId = (newProduct, previousProducts) => {
	const newKey = getProductIdOptionCompositeKey(newProduct);
	const previousProductsWithOptionIds = previousProducts.map((product) => ({
		product,
		oldKey: getProductIdOptionCompositeKey(product.toObject ? product.toObject() : product),
	}));

	const matchingProduct = previousProductsWithOptionIds.find((x) => x.oldKey === newKey);
	return matchingProduct ? matchingProduct.product._id : undefined;
};

const isMatchingPreviousProductSelected = (product, previousProducts) => {
	// Using filter here because there could be more than one productId matching
	const productIdMatches = previousProducts.filter((previousProduct) => {
		const previousProductId = invoke(previousProduct, 'productId.toString');
		const currentProductId = invoke(product, 'productId.toString');
		return previousProductId && currentProductId && previousProductId === currentProductId;
	});

	// productId match is unique so these are the same product.
	if (productIdMatches.length === 1) {
		return productIdMatches[0].userSelected;
	}

	// This is a more general way of matching products compared to the method above
	const matchedProduct = previousProducts.find(
		(previousProduct) => getOptionId(previousProduct) === getOptionId(product)
	);

	return matchedProduct ? matchedProduct.userSelected : false;
};

const updatedProductWithPreviousSelection = (newProducts, previousProducts) => {
	return newProducts.map((newProduct) => {
		const wasSelected = isMatchingPreviousProductSelected(newProduct, previousProducts);
		const previousId = findMatchingProductId(newProduct, previousProducts);

		return {
			...newProduct,
			...{ userSelected: wasSelected, _id: previousId },
		};
	});
};

const updateProductIds = (newProducts, previousProducts) => {
	return previousProducts.map((oldProduct) => {
		const oldKey = getProductIdOptionCompositeKey(oldProduct);
		const matchingProductOption = newProducts.find((product) => getProductIdOptionCompositeKey(product) === oldKey);

		return {
			...oldProduct,
			_id: matchingProductOption?._id ?? oldProduct._id,
		};
	});
};

// attributes of productsOffered items that vary with each pricing option
const productOptionFields = [
	'_id',
	'deductible',
	'paymentPrice',
	'productPricing',
	'suggestedRetailPrice',
	'suggestedRetailPricePretax',
	'termMiles',
	'termMonths',
	'userSelected',
	'visits',
];

/**
 * Get the portion of the productOffered that is specific to a single pricing option
 * @param {object} productOffered an item from loanApp.productsOffered
 */
const getProductOption = (productOffered) => pick(productOffered, productOptionFields);

module.exports = {
	calculateProductsAmount,
	getOptionId,
	getProductIdOptionCompositeKey,
	updatedProductWithPreviousSelection,
	getProductOption,
	productOptionFields,
	updateProductIds,
};
