export const initialState = {
	dealerAssociateId: '',
	email: '',
	firstName: '',
	fullName: '',
	isSubmitting: false,
	isTaxExempt: false,
	lastName: '',
	middleName: '',
	phone: '',
	suffix: 'NONE',
	title: '',
};

type State = typeof initialState;

export enum ActionType {
	SetDealerAssociateId,
	SetEmail,
	SetFirstName,
	SetInitialResult,
	SetIsSubmitting,
	SetLastName,
	SetMiddleName,
	SetPhone,
	SetSuffix,
	SetTaxExempt,
	SetTitle,
}

export type Action =
	| {
			dealerAssociateId: string;
			email: string;
			firstName: string;
			isTaxExempt: boolean;
			lastName: string;
			middleName: string;
			phone: string;
			suffix: string;
			title: string;
			type: ActionType.SetInitialResult;
	  }
	| { dealerAssociateId: string; type: ActionType.SetDealerAssociateId }
	| { email: string; type: ActionType.SetEmail }
	| { firstName: string; type: ActionType.SetFirstName }
	| { isSubmitting: boolean; type: ActionType.SetIsSubmitting }
	| { isTaxExempt: boolean; type: ActionType.SetTaxExempt }
	| { lastName: string; type: ActionType.SetLastName }
	| { middleName: string; type: ActionType.SetMiddleName }
	| { phone: string; type: ActionType.SetPhone }
	| { suffix: string; type: ActionType.SetSuffix }
	| { title: string; type: ActionType.SetTitle };

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case ActionType.SetDealerAssociateId:
			return { ...state, dealerAssociateId: action.dealerAssociateId };
		case ActionType.SetEmail:
			return { ...state, email: action.email };
		case ActionType.SetFirstName:
			return { ...state, firstName: action.firstName };
		case ActionType.SetInitialResult: {
			return {
				...state,
				dealerAssociateId: action.dealerAssociateId,
				email: action.email,
				firstName: action.firstName,
				isTaxExempt: action.isTaxExempt,
				lastName: action.lastName,
				middleName: action.middleName,
				phone: action.phone,
				suffix: action.suffix,
				title: action.title,
			};
		}
		case ActionType.SetIsSubmitting:
			return { ...state, isSubmitting: action.isSubmitting };
		case ActionType.SetLastName:
			return { ...state, lastName: action.lastName };
		case ActionType.SetMiddleName:
			return { ...state, middleName: action.middleName };
		case ActionType.SetPhone:
			return { ...state, phone: action.phone };
		case ActionType.SetSuffix:
			return { ...state, suffix: action.suffix };
		case ActionType.SetTaxExempt:
			return { ...state, isTaxExempt: action.isTaxExempt };
		case ActionType.SetTitle:
			return { ...state, title: action.title };
		default:
			throw new Error('Action dispatched in Identity form without a reducer case');
	}
};
