import { formatMessage } from '@public/js/loan-application/utils';
import { ApplicantNameSuffix } from '@client/graphql/generated';

export const PANEL_TRACKING_NAME = 'identity';

export const LOCALE_STRINGS = {
	panelHeader: (user: string) =>
		formatMessage(user === 'applicant' ? 'pii/identity/who-is-primary-applicant' : 'pii/identity/who-is-co-applicant'),
	panelDescription: (user: string) =>
		formatMessage(
			user === 'applicant'
				? 'pii/identity/we-will-save-your-information-as-you-enter-it-and-email-you'
				: 'pii/identity/we-will-save-your-information-as-you-enter-it'
		),
	email: formatMessage('pii/email'),
	title: formatMessage('pii/title'),
	nameFirst: formatMessage('pii/first-name'),
	nameLast: formatMessage('pii/last-name'),
	nameMiddle: formatMessage('pii/middle-name'),
	nameMustMatch: formatMessage('pii/identity/hint/must-match-your-driver-license'),
	numbersNotAllowed: formatMessage('pii/identity/hint/invalid-name-numbers-are-not-allowed'),
	invalidEmail: formatMessage('pii/identity/hint/invalid-email-address'),
	invalidPhone: formatMessage('pii/identity/hint/invalid-phone-number'),
	isTaxExempt: formatMessage('payment-details/taxes-modal/i-am-exempt'),
	isTaxExemptHint: formatMessage('payment-details/taxes-modal/proof-of-exemption'),
	phone: formatMessage('pii/phone-number'),
	suffix: formatMessage('pii/suffix'),
	checkingEmail: formatMessage('pii/identity/hint/checking-email'),
	duplicateEmail: formatMessage('pii/identity/hint/duplicate-email'),
};

export const ENUM_TITLE_VALUES = [
	{
		label: formatMessage('pii/identity/title/mr'),
		value: 'Mr',
	},
	{
		label: formatMessage('pii/identity/title/miss'),
		value: 'Miss',
	},
	{
		label: formatMessage('pii/identity/title/mrs'),
		value: 'Mrs',
	},
	{
		label: formatMessage('pii/identity/title/ms'),
		value: 'Ms',
	},
	{
		label: formatMessage('pii/identity/title/dr'),
		value: 'Dr',
	},
];

export const ENUM_SUFFIX_VALUES = [
	...Object.values(ApplicantNameSuffix)
		.sort((a, b) => {
			const order = ['NONE', 'SR', 'JR', 'I', 'II', 'III', 'IV', 'V'];
			return order.indexOf(a) - order.indexOf(b);
		})
		.map((suffix) => ({
			label: suffix === 'NONE' ? 'None' : suffix,
			value: suffix,
		})),
];
